<template>
  <div class="view-container">
    <el-card>
      <h3>
        <div
            class="clearfix"
            slot="header"
        >
          <span>{{ $t('system.version') }}</span>
        </div>
      </h3>
      <el-row>
        <h2>
          Back:{{ version.back }} / Front:{{ version.front }}
        </h2>
      </el-row>
    </el-card>
    <div
        class="view"
        v-if="form"
    >
      <div class="view-body">
        <el-card class="mb-4">
          <div
              class="clearfix"
              slot="header"
          >
            <label>{{ $t('system.instance_name') }}</label>
          </div>
          <el-row>
            <el-input
                @keyup.ctrl.enter.native="onSave"
                name="instance_name"
                size="normal"
                v-model="form.instance_name"
            >
            </el-input>
          </el-row>
        </el-card>
        <div class="row">
          <div class="col-4">
            <el-card class="mb-4">
              <div
                  class="clearfix"
                  slot="header"
              >
                <span>{{ $t('system.role_given_to') }}</span>
              </div>
              <!--<el-form-item>-->
              <el-select
                  :placeholder="$t('system.role_given_to')"
                  filterable
                  v-model="form.non_admin_project_role"
              >
                <el-option
                    :key="key"
                    :label="value"
                    :value="key"
                    v-for="(value, key) in option.roles"
                >
                </el-option>
              </el-select>
              <small
                  class="text-danger"
                  v-if="backend_errors.name"
              >
                {{ backend_errors.name[0] }}
              </small>
              <!--</el-form-item>-->
            </el-card>
            <el-card class="mb-4">
              <div
                  class="clearfix"
                  slot="header"
              >
                <span>{{ $t('system.reports_columns') }}</span>
              </div>
              <el-transfer
                  :data="form.reportTimeEntriesThead.all"
                  :filter-placeholder="$t('system.enter_the_name_of_the_search')"
                  :titles="[$t('issue.no-checked'), $t('issue.checked')]"
                  class="systemSetting--transfer"
                  filterable
                  v-model="form.reportTimeEntriesThead.selected"
              >
              </el-transfer>
            </el-card>
          </div>
          <div class="col-8">
            <el-card class="mb-4">
              <div
                  class="clearfix"
                  slot="header"
              >
                <span>{{ $t('system.due_date_config') }}</span>
              </div>
              <!--<el-form-item>-->
              <el-row>
                <label>{{ $t('system.days_ago') }}</label>
                <el-input-number
                    @keyup.ctrl.enter.native="onSave"
                    v-model="form.days_ago"
                >
                </el-input-number>
                <small
                    class="text-danger"
                    v-if="backend_errors.days_ago"
                >
                  {{ backend_errors.days_ago[0] }}
                </small>
                <label>{{ $t('system.priority_color') }}</label>
                <el-color-picker
                    class="color-picker"
                    v-model="form.priority_color"
                ></el-color-picker>
                <label>{{ $t('system.for_status') }}</label>
                <el-select
                    :placeholder="$t('system.for_status')"
                    filterable
                    multiple
                    v-model="form.priority_color_status"
                >
                  <el-option
                      :key="key"
                      :label="value"
                      :value="key"
                      v-for="(value, key) in option.status"
                  >
                  </el-option>
                </el-select>
              </el-row>
              <el-row>
                <label>{{ $t('system.due_date_color') }}</label>
                <el-color-picker
                    class="color-picker"
                    v-model="form.due_date_color"
                ></el-color-picker>
                <label>{{ $t('system.for_status') }}</label>
                <el-select
                    :placeholder="$t('system.for_status')"
                    filterable
                    multiple
                    v-model="form.due_date_color_status"
                >
                  <el-option
                      :key="key"
                      :label="value"
                      :value="key"
                      v-for="(value, key) in option.status"
                  >
                  </el-option>
                </el-select>
              </el-row>
              <el-row>
                <label>{{ $t('system.issue_ends_today') }}</label>
                <el-color-picker
                    class="color-picker"
                    v-model="form.issue_ends_today_color"
                ></el-color-picker>
                <label>{{ $t('system.for_status') }}</label>
                <el-select
                    :placeholder="$t('system.for_status')"
                    filterable
                    multiple
                    v-model="form.issue_ends_today_color_status"
                >
                  <el-option
                      :key="key"
                      :label="value"
                      :value="key"
                      v-for="(value, key) in option.status"
                  >
                  </el-option>
                </el-select>
              </el-row>
              <!--</el-form-item>-->
            </el-card>
            <div class="row">
              <div class="col-5">
                <el-card class="mb-4">
                  <div
                      class="clearfix"
                      slot="header"
                  >
                    <span>{{ $t('system.change_status_timetracker') }}</span>
                  </div>
                  <!--<el-form-item>-->
                  <el-row>
                    <!--<div class="col-md-6">-->
                    <el-form
                        class="select-full-width"
                        label-width="150px"
                    >
                      <el-form-item :label="$t('system.status_from')">
                        <!--<label>{{$t('system.status_from')}}</label>-->
                        <el-select
                            :placeholder="$t('system.status_from')"
                            filterable
                            v-model="form.status_from"
                        >
                          <el-option
                              :key="value.id"
                              :label="value.name"
                              :value="value.id"
                              v-for="value in option.statuses"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>

                      <el-form-item :label="$t('system.status_to')">
                        <el-select
                            :placeholder="$t('system.status_to')"
                            filterable
                            v-model="form.status_to"
                        >
                          <el-option
                              :key="value.id"
                              :label="value.name"
                              :value="value.id"
                              v-for="value in option.statuses"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>
                    <!--</div>-->
                  </el-row>
                  <!--</el-form-item>-->
                </el-card>
                <!--<el-card>-->
                <!--<div slot="header" class="clearfix">-->
                <!--<span>{{$t('system.logo')}}</span>-->
                <!--</div>-->
                <!--&lt;!&ndash;<el-form-item>&ndash;&gt;-->
                <!--<el-row>-->
                <!--<label>{{ $t('system.logo_path') }}</label>-->
                <!--<el-upload-->
                <!--drag-->
                <!--class="avatar-uploader"-->
                <!--:action="action"-->
                <!--:show-file-list="false"-->
                <!--:on-success="handleAvatarSuccess"-->
                <!--:before-upload="beforeAvatarUpload"-->
                <!--:headers="headerInfo">-->
                <!--<img v-if="form.logo_path" :src="download" class="avatar">-->
                <!--<i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
                <!--</el-upload>-->
                <!--</el-row>-->
                <!--&lt;!&ndash;</el-form-item>&ndash;&gt;-->
                <!--</el-card>-->
              </div>
              <div class="col-7">
                <el-card class="mb-4">
                  <div
                      class="clearfix"
                      slot="header"
                  >
                    <span>{{ $t('system.homepage') }}</span>
                  </div>
                  <!--<el-form-item>-->
                  <el-row>
                    <el-form
                        class="select-full-width"
                        label-width="150px"
                    >
                      <el-form-item :label="$t('system.overdue_status')">
                        <el-select
                            :placeholder="$t('system.overdue_status')"
                            filterable
                            multiple
                            v-model="form.overdue_status"
                        >
                          <el-option
                              :key="key"
                              :label="value"
                              :value="key"
                              v-for="(value, key) in option.status"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('system.today_status')">
                        <el-select
                            :placeholder="$t('system.today_status')"
                            filterable
                            multiple
                            v-model="form.today_status"
                        >
                          <el-option
                              :key="key"
                              :label="value"
                              :value="key"
                              v-for="(value, key) in option.status"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('system.future_status')">
                        <el-select
                            :placeholder="$t('system.future_status')"
                            filterable
                            multiple
                            v-model="form.future_status"
                        >
                          <el-option
                              :key="key"
                              :label="value"
                              :value="key"
                              v-for="(value, key) in option.status"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('system.in_progress')">
                        <el-select
                            :placeholder="$t('system.in_progress')"
                            filterable
                            multiple
                            v-model="form.in_progress"
                        >
                          <el-option
                              :key="key"
                              :label="value"
                              :value="key"
                              v-for="(value, key) in option.status"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('system.not_started')">
                        <el-select
                            :placeholder="$t('system.not_started')"
                            filterable
                            multiple
                            v-model="form.not_started"
                        >
                          <el-option
                              :key="key"
                              :label="value"
                              :value="key"
                              v-for="(value, key) in option.status"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>
                  </el-row>
                  <!--</el-form-item>-->
                </el-card>
                <el-card>
                  <div
                      class="clearfix"
                      slot="header"
                  >
                    <span>{{ $t('system.emails_config') }}</span>
                  </div>
                  <!--<el-form-item>-->
                  <el-row>
                    <div for="issueAdded">
                      <el-checkbox
                          id="issueAddded"
                          v-bind:checkbox="form.email_notifi_issue_created"
                          v-bind:label="$t('system.isssue_added')"
                          v-model="form.email_notifi_issue_created"
                      >
                      </el-checkbox>
                    </div>
                    <div for="issueUpdated">
                      <el-checkbox
                          id="issueUpdated"
                          v-bind:checkbox="form.email_notifi_issue_updated"
                          v-bind:label="$t('system.issue_updated')"
                          v-model="form.email_notifi_issue_updated"
                      >
                      </el-checkbox>
                    </div>
                  </el-row>
                  <!--</el-form-item>-->
                </el-card>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="row">-->
        <!--          <div class="col-12">-->
        <!--            <el-card class="mb-4">-->
        <!--            <div slot="header" class="clearfix">-->
        <!--              <span>{{$t('system.issues')}}</span>-->
        <!--            </div>-->
        <!--            <el-transfer-->
        <!--                    class="systemSetting&#45;&#45;transfer"-->
        <!--                    filterable-->
        <!--                    :filter-placeholder="$t('system.enter_the_name_of_the_search')"-->
        <!--                    :titles="[$t('issue.no-checked'), $t('issue.checked')]"-->
        <!--                    v-model="form.selectTheadIssue"-->
        <!--                    :data="theadIssue">-->
        <!--            </el-transfer>-->
        <!--              &lt;!&ndash;<div class="d-flex justify-content-start mt-4">&ndash;&gt;-->
        <!--                &lt;!&ndash;<el-button type="primary" @click.prevent.stop="saveConfig(selectTheadIssue, 'issueDefault')">{{$t('system.save_configs')}}</el-button>&ndash;&gt;-->
        <!--              &lt;!&ndash;</div>&ndash;&gt;-->
        <!--          </el-card>-->
        <!--            <el-card class="mb-4">-->
        <!--              <div slot="header" class="clearfix">-->
        <!--                <span>{{$t('report.report_client')}}</span>-->
        <!--              </div>-->
        <!--              <el-transfer-->
        <!--                      class="systemSetting&#45;&#45;transfer"-->
        <!--                      filterable-->
        <!--                      :filter-placeholder="$t('system.enter_the_name_of_the_search')"-->
        <!--                      :titles="[$t('issue.no-checked'), $t('issue.checked')]"-->
        <!--                      v-model="form.selectTheadRaportClient"-->
        <!--                      :data="theadRaport">-->
        <!--              </el-transfer>-->
        <!--              &lt;!&ndash;<div class="d-flex justify-content-start mt-4">&ndash;&gt;-->
        <!--                &lt;!&ndash;<el-button type="primary" @click.prevent.stop="saveConfig(selectTheadRaportClient, 'raportClientDefault')">{{$t('system.save_configs')}}</el-button>&ndash;&gt;-->
        <!--              &lt;!&ndash;</div>&ndash;&gt;-->
        <!--            </el-card>-->
        <!--            <el-card class="mb-4">-->
        <!--              <div slot="header" class="clearfix">-->
        <!--                <span>{{$t('report.report_internal')}}</span>-->
        <!--              </div>-->
        <!--              <el-transfer-->
        <!--                      class="systemSetting&#45;&#45;transfer"-->
        <!--                      filterable-->
        <!--                      :filter-placeholder="$t('system.enter_the_name_of_the_search')"-->
        <!--                      :titles="[$t('issue.no-checked'), $t('issue.checked')]"-->
        <!--                      v-model="form.selectTheadRaportInternal"-->
        <!--                      :data="theadRaport">-->
        <!--              </el-transfer>-->
        <!--              &lt;!&ndash;<div class="d-flex justify-content-start mt-4">&ndash;&gt;-->
        <!--                &lt;!&ndash;<el-button type="primary" @click.prevent.stop="saveConfig(selectTheadRaportInternal, 'raportInternalDefault')">{{$t('system.save_configs')}}</el-button>&ndash;&gt;-->
        <!--              &lt;!&ndash;</div>&ndash;&gt;-->
        <!--            </el-card>-->
        <!--            <el-card class="mb-4">-->
        <!--              <div slot="header" class="clearfix">-->
        <!--                <span>{{$t('system.my_page')}}</span>-->
        <!--              </div>-->
        <!--              <el-transfer-->
        <!--                      class="systemSetting&#45;&#45;transfer"-->
        <!--                      filterable-->
        <!--                      :filter-placeholder="$t('system.enter_the_name_of_the_search')"-->
        <!--                      :titles="[$t('issue.no-checked'), $t('issue.checked')]"-->
        <!--                      v-model="form.selectTheadIssueMyPage"-->
        <!--                      :data="theadIssueMyPage">-->
        <!--              </el-transfer>-->
        <!--              &lt;!&ndash;<div class="d-flex justify-content-start mt-4">&ndash;&gt;-->
        <!--                &lt;!&ndash;<el-button type="primary" @click.prevent.stop="saveConfig(selectTheadIssueMyPage, 'issueMyPageDefault')">{{$t('system.save_configs')}}</el-button>&ndash;&gt;-->
        <!--              &lt;!&ndash;</div>&ndash;&gt;-->
        <!--            </el-card>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <!--<div class="view d-flex justify-content-between">-->
    <div class="box-button align-right">
      <el-button
          :loading="loading"
          @click.prevent.stop="onSave"
          type="primary"
      >
        {{ $t('system.save') }}
      </el-button>
      <el-button @click.prevent.stop="backTo">
        {{ $t('system.cancel') }}
      </el-button>
    </div>
    <!--</div>-->
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import {mapGetters} from 'vuex';
import _ from 'lodash';
import Global from '../../helpers/global';
import notification from '../../notification/notify';

export default {
  name: 'SystemSettings',
  components: {},
  data() {
    return {
      form: null,
      backend_errors: {},
      option: {
        status: [],
        roles: [],
      },
      title: 'Create',
      selectTheadIssue: [],
      selectTheadRaportInternal: [],
      selectTheadRaportClient: [],
      selectTheadProjects: [],
      selectTheadIssueMyPage: [],
      initialize: 'system_settings',
      redirect: '/',
      store: 'system_settings',
      method: 'put',
      options: [],
      loading: false,
      // action: `${process.env.BASE_API}uploadLogo?clientId=${this.$store.getters['auth/clientId']}`,
      // headerInfo: {
      //   'Access-Control-Allow-Origin': `${process.env.BASE_API}uploadLogo&clientId=${this.$store.getters['auth/clientId']}`,
      //   'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
      //   'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
      //   'Authorization': 'Bearer ' + this.$store.getters['auth/token']
      // }
    };
  },
  computed: {
    ...mapGetters([
      'version',
      'uConfig',
    ]),
    // download () {
    //   let src = process.env.BASE_API.substring(process.env.BASE_API.length - 4, process.env.BASE_API);
    //   return `${src}/download?logo_path=${this.form.logo_path}`
    // },
    roles() {
      if (!_.isEmpty(this.option.roles)) {
        let roles = _.orderBy(this.option.roles, function (role) {
          return role.toLowerCase();
        });
        return roles;
      } else {
        return {};
      }
    },
    theadIssue() {
      return this.$store.getters['issue/get_column_name'];
    },
    theadIssueMyPage() {
      return this.$store.getters['issue/get_column_name'];
    },
    theadRaport() {
      return this.$store.getters['report/get_column_name'];
    },
    theadProjects() {
      return this.$store.getters['project/get_column_name'];
    },
  },
  beforeMount() {
    this.fetchData();
    this.updateConfig();
  },
  watch: {
    '$route': 'fetchData',
  },
  methods: {
    fetchData() {
      var vm = this;
      axios.get(`${this.initialize}/form`)
          .then(function (response) {
            vm.form = response.data.form;
            if (response.data.form.email_notifi_issue_created === '0') {
              vm.form.email_notifi_issue_created = false;
            } else {
              vm.form.email_notifi_issue_created = true;
            }
            if (response.data.form.email_notifi_issue_updated === '0') {
              vm.form.email_notifi_issue_updated = false;
            } else {
              vm.form.email_notifi_issue_updated = true;
            }
            vm.form.status_from = parseInt(response.data.form.status_from);
            vm.form.status_to = parseInt(response.data.form.status_to);
            if (typeof response.data.form.logo_path === 'undefined') {
              vm.form.logo_path = null;
            }
            vm.option = response.data.option;
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    onSave() {
      var vm = this;
      vm.loading = true;
      let form = this.form;
      axios[this.method](this.store, form)
          .then(function (response) {
            vm.loading = false;
            if (response.data.saved) {
              vm.$store.dispatch('getSystemSetting').then(() => {
                notification.notify(
                    vm.$t('notify.success'),
                    response.data.message,
                    'success');
              });
            } else {
              notification.notify(
                  vm.$t('notify.error'),
                  response.data.message,
                  'error');
            }
          })
          .catch(function (error) {
            vm.loading = false;
            let dataErrors = error.response.data.errors;
            let errors = Global.getErrors(vm, dataErrors, 1);

            vm.$store.commit('SET_STICKY_ARRAY', {
              array: errors,
            });
            Vue.set(vm.$data, 'backend_errors', dataErrors);
          });
    },
    backTo() {
      window.history.back();
    },
    handleAvatarSuccess(res, file) {
      this.form.logo_path = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('Avatar picture size can not exceed 10MB!');
      }
      return isLt2M;
    },
    async saveConfig(thead, key) {
      let vm = this;
      await axios.post(`user_config/`, {
        'data': {selectThead: thead},
        'key': key,
      })
          .then(function (response) {
            if (response.data.saved) {
              // vm.$store.commit('SET_uCONFIG_SELECTTHEAD', vm.selectTheadIssue);
              notification.notify(
                  vm.$t('notify.success'),
                  vm.$t('message.saved'),
                  'success');
            } else {
              notification.notify(
                  vm.$t('notify.error'),
                  response.data.message,
                  'error');
            }
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    updateConfig() {
      return new Promise((resolve) => {
        if (this._self['uConfig'].length === 0) {
          this.$store.dispatch('getMyConfig').then(() => {
            this.setUConfig();
          });
        } else {
          this.setUConfig();
        }
        resolve();
      });
    },
    setUConfig() {
      let issueDefault = null;
      let raportClientDefault = null;
      let raportInternalDefault = null;
      let projectsDefault = null;
      let issueMyPageDefault = null;
      if (this._self['uConfig']) {
        if (this.uConfig.data) {
          if (this.uConfig.data.issueDefault) {
            issueDefault = _.cloneDeep(this.uConfig.data.issueDefault);
          }
          if (this.uConfig.data.raportClientDefault) {
            raportClientDefault = _.cloneDeep(this.uConfig.data.raportClientDefault);
          }
          if (this.uConfig.data.raportInternalDefault) {
            raportInternalDefault = _.cloneDeep(this.uConfig.data.raportInternalDefault);
          }
          if (this.uConfig.data.projectsDefault) {
            projectsDefault = _.cloneDeep(this.uConfig.data.projectsDefault);
          }
          if (this.uConfig.data.issueMyPageDefault) {
            issueMyPageDefault = _.cloneDeep(this.uConfig.data.issueMyPageDefault);
          }
        }
        if (issueDefault) {
          if (issueDefault.selectThead) {
            this.selectTheadIssue = issueDefault.selectThead;
          }
        }
        if (raportClientDefault) {
          if (raportClientDefault.selectThead) {
            this.selectTheadRaportClient = raportClientDefault.selectThead;
          }
        }
        if (raportInternalDefault) {
          if (raportInternalDefault.selectThead) {
            this.selectTheadRaportInternal = raportInternalDefault.selectThead;
          }
        }
        if (projectsDefault) {
          if (projectsDefault.selectThead) {
            this.selectTheadProjects = projectsDefault.selectThead;
          }
        }
        if (issueMyPageDefault) {
          if (issueMyPageDefault.selectThead) {
            this.selectTheadIssueMyPage = issueMyPageDefault.selectThead;
          }
        }
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.avatar {
  width: auto;
  height: auto;
  display: block;
}

.el-upload-dragger {
  min-width: 320px;
  min-height: 180px;
  width: auto !important;
  height: auto !important;
  display: block;
}

.color-picker {
  top: 15px
}

.select-full-width {
  .el-select {
    width: 100%;
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.systemSetting--transfer {
  display: flex;
  align-items: center;

  .el-transfer-panel {
    width: 100% !important;
  }
}
</style>
